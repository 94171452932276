import React, { useState } from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NatePad = ({ data, location }) => {
  const [isDisabled, setIsDisabled] = useState(true);
  const [nates, setNates] = useState("");
  const siteTitle = data.site.siteMetadata.title

  const handleNatesInput = () => {
    const lastCharacter = nates.substring(nates.length - 1);
    let nextCharacter;

    switch (lastCharacter) {
      case "n":
        nextCharacter = "a"
        break;
      case "a":
        nextCharacter = "t"
        break;
      case "t":
        nextCharacter = "e"
        break;
      case "e":
        nextCharacter = " "
        break;
      case " ":
        nextCharacter = "n"
        break;
      default:
        nextCharacter = "n"
        break;
    }

    setNates(nates + nextCharacter);
  }

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="NatePad - Notepad for Nates" />
      <main className="flex flex-col p-6">
        <h1 className="text-xl font-extrabold mb-6">NatePad - Notepad for only Nates</h1>
        <input onChange={(event) => { if(event.target.value === "Nate") { setIsDisabled(false) }}} className="mb-4 text-lg md:text-xl" type="password" placeholder="Enter what all Nates put on the top of their homework."/>
        {isDisabled && <p className="">Enter the password, or else. Only Nates could possibly know it</p>}
        {!isDisabled && <textarea className="h-64 text-lg md:text-xl" value={nates} onChange={handleNatesInput} disabled={isDisabled} /> }
      </main>
    </Layout>
  )
}

export default NatePad

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
